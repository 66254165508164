import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, styled,//Grid, Icon,
  Select, MenuItem, ListItemIcon, ListItemText,  
} from "@mui/material";
import { grey } from "@mui/material/colors";
import ListIcon from "@mui/icons-material/List";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Button, Typography } from 'core';
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
import { socialMedia } from 'config/ui.config';
import '../style.css';
//import OrgGroupsContextProvider from 'context/orgGroups-context';
import { selectUserOrgId, checkIsAdmin } from 'services/auth/authSlice';
import { useOrgGroupsQuery } from 'services/orgApi';
import { useGetSMediaDataQuery } from 'services/sMediaApi';
import {
  initFeedListModifiers, getOrgGroupsData, getSMFilterData,
  getFeedListModifiers, //setFeedListSearchText
  setSelectedGroupFilter, setSelectedSMFilter,
  setMediaFilterParams,
} from 'services/orgFeedApi';
//import Spinner2 from 'core/Spinner/Spinner2';
//---------------------------------------------------------

const StyledTabContainer = styled(Stack)(() => ({
  flexDirection: 'row', alignItems: 'center',
  flexGrow: 1, overflowX: 'auto', // cursor: 'pointer',
}));
const StyledButton = styled(Button)(({ theme, disablepadding }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '0.55rem 0',
  width: !disablepadding ? '100%' : '10%',
  alignItems: 'center',
  border: `1px solid #e6e7e9`,
  textTransform: 'none',
  color: grey[500],
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: { minWidth: '35px !important', },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '0.1rem 0',
  alignItems: 'center',
  width: '101%',
  outline: 'none',
  border: 'none',
  color: grey[500],
  '&:hover': { backgroundColor: theme.palette.background.paper, },
  [theme.breakpoints.down('sm')]: {
    minWidth: '65px',
    '& .MuiSelect-select': { padding: '7.5px 4px ', },
  },
}));
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '35px',
  [theme.breakpoints.down('sm')]: { minWidth: '5px', },
}));
const StyledFilterAltIcon = styled(FilterAltIcon)(({ theme }) => ({
  color: grey[500],
  [theme.breakpoints.down('sm')]: { fontSize: '20px', },
}));
const StyledGroupsIcon = styled(GroupsIcon)(({ theme }) => ({
  color: grey[500],
  [theme.breakpoints.down('sm')]: { fontSize: '20px', },
}));
const StyledListIcon = styled(ListIcon)(({ theme }) => ({
  color: grey[500],
  [theme.breakpoints.down('sm')]: { fontSize: '20px', },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.down('sm')]: { fontSize: '12px', },
}));
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.down('sm')]: { fontSize: '12px', },
}));
//---------------------------------------------------------

function FeedMenuList({ updated, setUpdated, setPage, setGetSMFilterParam }) {
  const isAdmin = useSelector(checkIsAdmin);
  const {
    data: dDataA,
    error: dErrA,
    isLoading: dWaitA,
    isFetching: dFetchA,
    isSuccess: dOKA,
  } = useGetSMediaDataQuery({isAdmin: isAdmin}, { skip: false });

  const [filter, setFilter] = useState(['all']);
  const [selGroup, setSelGroup] = useState(SA_DEF_ORG_GROUP_ID);
  //const [stSelGroupId, setStSelGroupId] = useState(SA_DEF_ORG_GROUP_ID);
  const [orgGroupOK, setOrgGroupOK] = useState(false);
  //const sTextRef = useRef();
  const dispatch = useDispatch();
  //console.log('filter', filter);

  let oGSuccess, orgGroups;
  const orgId = useSelector(selectUserOrgId);
  const { data: orgGroupsA, isSuccess: oGSuccessA } = useOrgGroupsQuery(
    { orgId: orgId },
    { skip: orgGroupOK }
  );
  oGSuccess = oGSuccessA;
  //orgGroups = oGSuccess && orgGroupsA ? orgGroupsA?.company : null;

  useEffect(() => {
    if (orgGroupsA) {
      setOrgGroupOK(true);
      dispatch(initFeedListModifiers(orgGroupsA));
    }
  }, [orgGroupsA, dispatch, setOrgGroupOK]);
  orgGroups = useSelector(getOrgGroupsData, () => orgGroupOK);
  const feedListModifiers = useSelector(getFeedListModifiers, () => orgGroupOK);
  //feedListModifiers && console.log('feedListModifiers', feedListModifiers);
  //setSelGroup(feedListModifiers?.selGroups[0]);
  //---------------------------------------------------------
  const fChangeOrgGroupHandler = (e) => {
    console.log('selGrp', e.target.value);
    //const { gval } = e.currentTarget.dataset;
    const gval = e.target.value;
    setSelGroup(gval);
    setPage(1);
    let Ids = [];
    Ids.push(gval);
    let selVal = [];
    selVal = Array.from(Ids).map((value) => value);
    dispatch(setSelectedGroupFilter({ selGroups: selVal }));
  };
  const fChangeSelSMFilterHandler = (e, smdata) => {
    e.preventDefault();
    // let selVal = smdata.map((option) => option);
    let mfp = smdata.map((option) => dDataA[option].sMId);
    mfp = mfp.filter((option) => option != null);
    
    updated === 'filterEnded' && setUpdated('filterStarted');
    //  selVal = [...e.target.options].filter(option => option.selected)
    //   	.map(option => option.value);
    setGetSMFilterParam(mfp);
    dispatch(setMediaFilterParams(mfp));
    dispatch(setSelectedSMFilter({ selSMs: smdata }));
  };

  let oGroupOptions = [];
  orgGroups &&
    Object.keys(orgGroups).forEach((gId) => {
      oGroupOptions.push(
        //selected={orgGroups[gId].selected}
        <MenuItem
          key={'grp_' + gId}
          data-gval={gId}
          //value={{id: gId, name: orgGroups[gId].title, }}
          value={gId}
          name={orgGroups[gId].title}
          //onClick={fChangeOrgGroupHandler}
          sx={{ fontWeight: gId === selGroup ? '800' : 'normal' }}
        >
          {orgGroups[gId].title}
        </MenuItem>
      );
    });

  useMemo(() => {
    let debounceTimer;
    debounceTimer = setTimeout(() => {}, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [filter]);

  useMemo(() => {
    let debounceTimer;
    debounceTimer = setTimeout(() => {
      //dispatch(setSelectedGroupFilter({selGroups: selGroup}));
    }, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [selGroup]);

  let filterSMOpt = [
    <MenuItem
      key={'all'}
      value={'all'}
    >
      <StyledListItemIcon>{socialMedia.all}</StyledListItemIcon>
      <StyledListItemText
        primary={'All'}
        // primary={filterSM[sK].title}
        sx={{
          fontWeight: 800,
        }}
      />
    </MenuItem>,
  ]; //let redSMMgr = null;
  let filterSM = useSelector(getSMFilterData, () => orgGroupOK);
  filterSM &&
    Object.keys(filterSM).forEach((sK) => {
      ['fb', 'tw', 'ln', 'ig', 'igb', 'igp'].includes(sK) &&
      filterSMOpt.push(
        <MenuItem
          key={'sMF_' + sK}
          value={sK}
          // value={{id:sK,namefilterSM[sK]?.title}}

          // onClick={(e) => fChangeSelSMFilterHandler(e, filter)}
        >
          <StyledListItemIcon>
            {socialMedia[filterSM[sK]?.title]}
          </StyledListItemIcon>
          <StyledListItemText
            primary={
              filterSM[sK]?.title.charAt(0).toUpperCase() +
              filterSM[sK]?.title.slice(1)
            }
            // primary={filterSM[sK].title}
            sx={{
              fontWeight:
                filter.includes(sK.title) === socialMedia[sK.title]
                  ? 800
                  : 'normal',
            }}
          />
        </MenuItem>
      );
    });
  const handleCloseMultiSelect = () => setMultiSelectOpen(false);

  const handleOpenMultiSelect = () => setMultiSelectOpen(true);

  const [multiSelectOpen, setMultiSelectOpen] = useState(false);

  const letsSetFilter = (data) => {
    let returning = data;
    if(data[0] === 'all') {
      data.shift();
      returning = data;
    }
    if(data[data.length - 1] === 'all' || data.length === 0) {
      data = ['all'];
      returning = [];
    }

    setFilter(data);
    setPage(1);
    handleCloseMultiSelect();
    return returning;
  };

  return (orgGroups ? (
    <StyledTabContainer
      container="true"
      className="NIU-Home-FeedMenueList-3DH5jIRhQt button-grp"
      id='ctnFFilter'
    >
      {/*<StyledButton item="true" disablepadding="true">
        <StyledListIcon />
      </StyledButton>*/}

      {/*<StyledButton startIcon={<SwapVertIcon sx={{ color: grey[500] }} />}>
        <StyledTypography color_variant="lightGrey">Sort</StyledTypography>
      </StyledButton>*/}

      <StyledSelect
        onOpen={handleOpenMultiSelect}
        onClose={handleCloseMultiSelect}
        open={multiSelectOpen}
        fullWidth
        displayEmpty
        value={filter}
        multiple
        onChange={(e) => {
          const data = letsSetFilter(e.target.value);
          fChangeSelSMFilterHandler(e, data);
        }}
        //defaultValue=""
        size="small"
        inputProps={{MenuProps: {disableScrollLock: true}}}
        renderValue={(selected) => {
          return (
            <>
              {selected.length && selected[0] !== 'all' ? (
                selected.length > 1 ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    {selected.map((network) => (
                        <>
                          <StyledListItemIcon>
                            {socialMedia[`${filterSM[network]?.title}_xs`]}
                          </StyledListItemIcon>
                          {/*<StyledTypography
                            size="medium"
                            color_variant="lightGrey"
                          >
                            {filterSM[network].title + }
                          </StyledTypography>*/}
                        </>
                      ))}

                    {/* <>
                          <StyledListItemIcon>
                            {socialMedia[`${selected}_xs`]}
                          </StyledListItemIcon>
                          <StyledTypography
                            size="medium"
                            color_variant="lightGrey"
                          >
                            Multiple
                          </StyledTypography>
                        </> */}

                    {/*<StyledListItemIcon>
                      <FilterAltIcon />
                    </StyledListItemIcon>}
                    <StyledTypography size="medium" color_variant="lightGrey">
                      Filter
                    </StyledTypography>*/}
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <StyledListItemIcon>
                      {socialMedia[`${filterSM[selected].title}_xs`]}
                    </StyledListItemIcon>
                    <StyledTypography size="medium" color_variant="lightGrey">
                      {filterSM[selected].title.charAt(0).toUpperCase() +
                        filterSM[selected].title.slice(1)}
                    </StyledTypography>
                  </Stack>
                )
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <StyledListItemIcon>
                    <FilterAltIcon className="filter-icon" />
                  </StyledListItemIcon>
                  <StyledTypography size="medium" color_variant="lightGrey">
                    All
                  </StyledTypography>
                </Stack>
              )}
            </>
          );
        }}
      >
        {filterSMOpt}
      </StyledSelect>

      <StyledSelect
        fullWidth
        displayEmpty
        value={selGroup} //defaultValue=""
        onChange={(e) => {
          //setSelGroup(e.target.value.name);
          fChangeOrgGroupHandler(e)
        }}
        size="small"
        inputProps={{MenuProps: {disableScrollLock: true}}}
        renderValue={(selected=selGroup) => {
          return (
            <Box>
              {selected ? (
                <Stack direction="row">
                  <StyledListItemIcon>
                    {socialMedia[`${selected}`]}
                  </StyledListItemIcon>
                  <StyledTypography size="medium" color_variant="lightGrey">
                    {orgGroups[selected].title}
                  </StyledTypography>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <StyledListItemIcon>
                    <StyledGroupsIcon className="group-icon" />
                  </StyledListItemIcon>
                  <StyledTypography color_variant="lightGrey">
                    Groups
                  </StyledTypography>
                </Stack>
              )}
            </Box>
          );
        }}
      >
        {oGroupOptions}
      </StyledSelect>
    </StyledTabContainer>
  ) : <></>
  );
}

// <Tabs
//   value={value}
//   onChange={handleChange}
//   TabIndicatorProps={{ style: { display: "none" } }}
//   variant="fullWidth"
// >
//   {tabsData.map((data) => (
//     <StyledTab icon={data.icon} iconPosition="start" label={data.label}/>
//   ))}
// </Tabs>
export default FeedMenuList;
