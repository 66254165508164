import { useCallback } from 'react';
import { Grid } from '@mui/material'; //Stack, styled, IconButton
import { Card } from 'core';
import { FeedView } from 'config/ui.config';
import '../style.css';
import PostHeader from './PostHeader';
import PostContent from './PostContent';
import PostCommandBar from './PostCommandBar';
import { prepPostCustomData } from 'lib/appFx';
//import { getSMediaMgr } from 'services/sMediaApi';
//import { useSelector } from 'react-redux';
//---------------------------------------------------------

//export default function FeedCard({
export default function ({
  theme,
  feedListMeta,
  feedData,
  onFeedEngagement,
  defEngPoints,
  sNetworkP,
  showCongratsFeedForm,
  setShowCongratsFeedForm,
  isUserList,
  // handleSocialEngagementActions,//?WHYAgain
}) {
  //const sMMeta = feedListMeta?.sMediaMgr;
  //const sMIdKeyMap = feedListMeta?.sMIdKeyMap;
  //const feedListModifiers = feedListMeta?.feedListModifiers;
  //const authUserData = feedListMeta?.authUserData;

  let pInf;
  if(feedListMeta && feedListMeta?.sMediaMgr) {
    //ShouldBeCalledFromFeedList&sentAsProp.
    //The pInf is required in UserFeedList Edit&Share..
    pInf = prepPostCustomData({ feedListMeta, feedData, sNetworkP });
  }
  //pInf && pInf?.postId === 'a1R3BYmO_v5eaMMYn' && console.log('pInf_'+pInf?.postId, pInf);
  //----------------------

  //const getSMediaTokens = useSelector(getSMediaMgr);

  //const getSMediaTokensFunction = (actParams) => {
  // return getSMediaTokens[actParams.destSMKey].sMTokens.properties[0].id;
  //};

  const fFeedActionHandler = useCallback((params, fCallback, errCb) => {
    // if (params?.destSMKey === 'fb' && params?.actType !== 'like') {
    //   window.FB.ui({ method: 'share', href: params.pUrl }, function (response) {
    //     //console.log('FbUResp', response);
    //     try {
    //       if (response) {
    //         onFeedEngagement(params, fCallback, errCb);
    //       } else {
    //       }
    //     } catch (err) {}
    //   });
    // } else {
    //   onFeedEngagement(params, fCallback, errCb);
    // }

    let shareUrl;
    let text = encodeURIComponent(params.feedData.text || '');
    let media = encodeURIComponent(params.feedData.media.pictures[0] || '');
    let url = params.pUrl || media;
    let app_id = 760322742396577;

    if (params?.destSMKey === 'fb' && params?.actType !== 'like') {
      shareUrl = `https://www.facebook.com/dialog/feed?app_id=${app_id}&link=${url}&picture=${media}&name=${text}&caption=${text}&description=${text}`;

      // Open a new window for the Facebook share
      const popup = window.open(shareUrl, 'facebook-share-dialog', 'width=626,height=436');

      // Polling to check if the popup is closed
      const pollTimer = window.setInterval(() => {
        if(popup.closed !== false) {
          window.clearInterval(pollTimer);
          onFeedEngagement(params, fCallback, errCb);
        }
      }, 200);
    } else {
      onFeedEngagement(params, fCallback, errCb);
    }
  }, []);
  //---------------------------------------------------------

  return (pInf ? (
    <Grid container alignItems="center" justifyContent="center" key={feedData.id} className={isUserList ? 'card-slide-main xtBdr2' : ''}>
      <Grid item xs={12} className={isUserList ? 'card-main' : ''} sx={{ border: '0px solid blue' }}>
        <Card className={isUserList ? 'card-slide' : ''}>
          <Grid container sx={{ border: '0px solid blue', padding: '0' }} flexDirection={FeedView['full'].feed_direction} justifyContent="space-between">
            {/*FeedHeader*/}
            <PostHeader theme={theme} postCustomData={pInf} handleCreateFeedForm={null} />
            {/*EndFeedHeader*/}

            {/*FeedContentText:
						feedListMeta={} fFeedActionHandler={} defEngPoints={}*/}
            <PostContent theme={theme} postCustomData={pInf} feedData={feedData} isUserList={isUserList} />
            {/*EndFeedContentText*/}
          </Grid>

          {/*FeedCommanBar*/}
          {!isUserList && (
            <PostCommandBar theme={theme}
              feedListMeta={feedListMeta}
              postCustomData={pInf}
              feedData={feedData}
              fFeedActionHandler={fFeedActionHandler}
              defEngPoints={defEngPoints}
              showCongratsFeedForm={showCongratsFeedForm}
              setShowCongratsFeedForm={setShowCongratsFeedForm}
            />
          )}
          {/*EndFeedCommanBar*/}
        </Card>

        {/*FeedActionModals*/}
        {/*EndFeedActionModals*/}
      </Grid>
    </Grid>
    ) : null
  );
}
